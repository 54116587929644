<template>
  <div class="group-winners">
    <div class="group-winners__header">
      <atomic-cat-heading
        :icon="
          getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.latestWinners.icon')
        "
      >
        {{
          getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.latestWinners.label')
        }}
      </atomic-cat-heading>
    </div>
    <div ref="sliderNode" class="group-winners__viewport">
      <div class="group-winners__container">
        <div v-for="(winner, index) in latestWinners" :key="`${index}-${winner.gameId}`" class="group-winners__slide">
          <card-latest-winners v-bind="winner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import emblaCarouselVue from 'embla-carousel-vue';
  import throttle from 'lodash/throttle';
  import Autoplay from 'embla-carousel-autoplay';

  import type { IWinner, IWebSocketResponse } from '@skeleton/core/types';

  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent, headerCountry } = storeToRefs(globalStore);
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);
  const { getContent } = useProjectMethods();
  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);
  const { getLatestWinners } = useCoreGamesApi();
  const autoplayOptions = {
    delay: 4000,
    stopOnInteraction: false,
    stopOnMouseEnter: true,
  };

  const latestWinners = ref<IWinner[]>([]);
  const winnersSubscription = ref<any>();

  const [sliderNode] = emblaCarouselVue(
    {
      loop: true,
      align: 'start',
    },
    [Autoplay(autoplayOptions)]
  );

  const updateWinners = throttle(
    (winnerData: IWebSocketResponse): void => {
      const { winner } = winnerData.data;
      const filteredWinners = latestWinners.value.filter(item => item.gameId !== winner?.gameId);
      if (winner) latestWinners.value = [winner, ...filteredWinners].slice(0, 12);
    },
    3000,
    { leading: false }
  );

  const subscribeWinnersSocket = (): void => {
    const { createSubscription } = useWebSocket();
    winnersSubscription.value = createSubscription(
      `game:winners:${isMobile.value ? 'mobile' : 'desktop'}:${profile.value?.country || headerCountry.value || 'UA'}`,
      updateWinners
    );
  };

  const unsubscribeWinnersSocket = (): void => {
    if (winnersSubscription.value) {
      winnersSubscription.value.unsubscribe();
      winnersSubscription.value.removeAllListeners();
    }
  };

  onMounted(async () => {
    try {
      latestWinners.value = await getLatestWinners({
        platform: isMobile.value ? 1 : 2,
        perPage: 12,
        countryCode: profile.value?.country || headerCountry.value || 'UA',
      });
      await nextTick();
      subscribeWinnersSocket();
      useListen('webSocketReconnected', subscribeWinnersSocket);
    } catch (e) {
      console.error('getWinners', e);
    }
  });

  onBeforeUnmount(() => {
    unsubscribeWinnersSocket();
    useUnlisten('webSocketReconnected', subscribeWinnersSocket);
  });
</script>

<style src="~/assets/styles/components/group/winners.scss" lang="scss" />
